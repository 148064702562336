import isEmailValid from '../../../util/emailValidation';

// eslint-disable-next-line import/prefer-default-export
export const isValid = (company) => {
  let isDataValid = true;
  if (
    !company.name ||
    !company.contactPerson ||
    !company.street ||
    !company.houseNumber ||
    !company.city ||
    !company.country ||
    !company.zipcode ||
    !isEmailValid(company.email) ||
    !company.vatNumber
  ) {
    isDataValid = false;
  }

  return isDataValid;
};
