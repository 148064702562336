import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import Fade from 'react-bootstrap/Fade';
import { useTranslation } from 'react-i18next';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './components/footer';
import NavbarComponent from './components/navbarComponent';
import AcceptJob from './pages/accept-job/acceptJob';
import Calculation from './pages/calculation/calculation';
import CompanyDetails from './pages/company-details/companyDetails';
import DeliverJob from './pages/deliver-job/deliverJob';
import HomeClient from './pages/home-client/homeClient';
import HomeTransporter from './pages/home-transporter/homeTransporter';
import JobDetails from './pages/job-details/jobDetails';
import LoginPage from './pages/login-page/loginPage';
import Order from './pages/order/order';
import PickupJob from './pages/pickup-job/pickupJob';
import getRoles from './setup/auth/api/getRoles';
import './setup/language/i18n';
import useUserStore from './setup/stores/userStore';

function App() {
  const { isAuthenticated, getAccessTokenSilently, isLoading, user } = useAuth0();
  const [updateIsClient, updateIsTransporter, updateBlockedUntil, isClient, isTransporter] =
    useUserStore((state) => [
      state.updateIsClient,
      state.updateIsTransporter,
      state.updateBlockedUntil,
      state.isClient,
      state.isTransporter,
    ]);
  const { i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    toast.info('Transports may be delayed due to holiday season 🎄');
  }, []);

  useEffect(() => {
    const getRolesFromBackend = async () => {
      try {
        const token = await getAccessTokenSilently();
        const updatedRoles = await getRoles(token, user.email);
        if (updatedRoles) {
          updateIsTransporter(updatedRoles.includes('Transporter'));
          updateIsClient(updatedRoles.includes('Client'));
        }
        // Update blockedUntil using the same token
        await updateBlockedUntil(token);
      } catch (e) {
        console.error('Error while fetching roles', e);
      }
    };
    if (isAuthenticated) {
      getRolesFromBackend();
    }
  }, [isAuthenticated]);

  return (
    <div>
      {!isAuthenticated && !isLoading && !user && <LoginPage />}
      <Fade in={isAuthenticated && !isLoading && user != null}>
        <div>
          <Router>
            {isAuthenticated && !isLoading && (
              <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                <NavbarComponent isAuthenticated={isAuthenticated} username={user.nickname} />
                <div style={{ padding: 18, fontSize: 20, color: 'red' }}>
                  <ToastContainer
                    position="top-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={false}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    pauseOnHover
                    theme="dark"
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <Routes>
                    <>
                      {isClient && (
                        <>
                          <Route path="/" element={<HomeClient i18n={i18n} />} />
                          <Route path="/associate/:id" element={<HomeClient i18n={i18n} />} />
                          <Route path="/calculation" element={<Calculation />} />
                        </>
                      )}
                      {isTransporter && <Route path="/" element={<HomeTransporter />} />}
                      <>
                        <Route path="/order/:id" element={<Order />} />
                        <Route path="/jobs/:id" element={<JobDetails />} />
                        <Route path="/jobs/:id/accept" element={<AcceptJob />} />
                        <Route path="/jobs/:id/pickup" element={<PickupJob />} />
                        <Route path="/jobs/:id/deliver" element={<DeliverJob />} />
                        <Route path="/company" element={<CompanyDetails />} />
                      </>
                    </>
                    {/* <Route path="*" element={<NotFound />} /> */}
                  </Routes>
                </div>
                <div>
                  <Footer />
                </div>
              </div>
            )}
          </Router>
        </div>
      </Fade>
    </div>
  );
}

export default App;
