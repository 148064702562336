import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

export default function VehicleDetails(props) {
  const { showValidation, onChange, defaultLength, formData } = props;

  const onChangeVehicle = (vehicle, property, value) => {
    const updatedVehicles = [...formData.vehicles];
    const updatedVehicle = updatedVehicles.find((x) => x.vehicleId === vehicle.vehicleId);
    updatedVehicle[property] = value;
    onChange(updatedVehicles);
  };

  const getVehicleTitle = (vehicle) => {
    const { vehicleType, electric, nonRunner } = vehicle;
    const vehicleTypePart = vehicleType;
    const electricPart = electric ? ' electric' : '';
    const nonRunnerPart = nonRunner ? ' non-runner' : '';
    const title = `${vehicleTypePart}${electricPart}${nonRunnerPart}`;
    return title;
  };

  const getVehicles = () => {
    return formData.vehicles.map((vehicle) => {
      return (
        <div key={vehicle.vehicleId}>
          <h6 className="mt-3" style={{ fontStyle: 'italic' }}>
            {`Vehicle ${getVehicleTitle(vehicle)}`}
          </h6>
          <Row>
            <Col xs={12} lg={4}>
              <Form.Group controlId="vehicleModel">
                <Form.Label>Brand and Model*</Form.Label>
                <Form.Control
                  type="text"
                  value={vehicle.model ?? ''}
                  onChange={(e) => onChangeVehicle(vehicle, 'model', e.target.value)}
                  required
                  isInvalid={showValidation && !vehicle.model}
                  maxLength={defaultLength}
                />
              </Form.Group>
            </Col>

            <Col xs={12} lg={4}>
              <Form.Group controlId="chassisNumber">
                <Form.Label>Chassis number*</Form.Label>
                <Form.Control
                  type="text"
                  value={vehicle.chassisNumber ?? ''}
                  onChange={(e) => onChangeVehicle(vehicle, 'chassisNumber', e.target.value)}
                  required
                  isInvalid={
                    showValidation &&
                    (!vehicle.chassisNumber || vehicle.chassisNumber.length !== 17)
                  }
                  maxLength={defaultLength}
                />
              </Form.Group>
            </Col>

            <Col xs={12} lg={4}>
              <Form.Group controlId="value">
                <Form.Label>Value*</Form.Label>
                <Form.Control
                  type="number"
                  min="0"
                  value={vehicle.value}
                  onChange={(e) => onChangeVehicle(vehicle, 'value', e.target.value)}
                  isInvalid={showValidation && (!vehicle.value || vehicle.value < 0)}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
      );
    });
  };

  return (
    <div>
      <h2 style={{ marginTop: 50 }}>Vehicle details</h2>
      <h6>We need additional information to complete your order</h6>
      <Row>
        <Col xs={12} lg={12}>
          {getVehicles()}
        </Col>
        <Col />
      </Row>
    </div>
  );
}
