import isEmailValid from '../../../util/emailValidation';

// eslint-disable-next-line import/prefer-default-export
export const isValid = (formData, step) => {
  let isDataValid = true;

  if (step === 1) {
    if (
      !formData.name ||
      !formData.street ||
      !formData.houseNumber ||
      !formData.city ||
      !formData.country ||
      !formData.vatNumber ||
      !isEmailValid(formData.email)
    ) {
      isDataValid = false;
    }
  }

  if (step === 2 || step === 3) {
    const company = step === 2 ? formData.companyFrom : formData.companyTo;
    if (
      !company.street ||
      !company.houseNumber ||
      !company.city ||
      !company.country ||
      !company.contactPerson ||
      !company.telephone ||
      !company.language ||
      !isEmailValid(company.email)
    ) {
      isDataValid = false;
    }
  }
  if (step === 4) {
    formData.vehicles.forEach((vehicle) => {
      if (
        !vehicle.model ||
        !vehicle.value ||
        (vehicle.value && vehicle.value < 0) ||
        !vehicle.chassisNumber ||
        (vehicle.chassisNumber &&
          vehicle.chassisNumber.length &&
          vehicle.chassisNumber.length !== 17)
      ) {
        isDataValid = false;
      }
    });
  }

  if (step === 5) {
    if (
      !formData.readyFrom ||
      new Date(formData.readyFrom).setHours(23, 59, 59, 999) < new Date() ||
      !formData.isAccepted
    ) {
      isDataValid = false;
    }
  }

  return isDataValid;
};
